import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"
import { useTrackVisibility } from "react-intersection-observer-hook"

import Layout from "components/layout"
import SEO from "components/seo"
import SlantedSection from "components/sections/slant"
import Demand360Lite from "components/demand360-lite"
import StyledSlantedSection from "components/sections/styled-slant"
import ArticleShowcase from "components/sections/article-showcase"
import LightSlantedSection from "components/sections/light-slant"
import FeaturedVideo from "components/sections/featured-video"
import VerticalLine from "components/vertical-line"
import Demand360ProductProfiles from "components/sections/demand360-product-profiles"
import Demand360Globe from "components/sections/demand360-globe"
import SectionBackground from "components/section-background"
import Demand360AddonModule from "components/sections/demand360-addon-module"
import PartnerLogos from "components/partner-logos"
import ExploreOtherProducts from "components/sections/explore-other-products"
import ActiveRouteContext from "context/active-route-context"
import BgWaves from "images/bg/waves.jpg"
import FeaturedPressCarousel from "components/featured-press-carousel"
import { getSharePreviewUrls } from "utils/share-preview"
import ProductVideoSection from "components/sections/product-video-section"

const Container = styled.div`
  ${tw`mx-auto relative pb-24 l:pb-16`}

  .body {
    ${tw`text-center l:w-4/5`}

    h1 {
      ${tw`font-bold text-white mt-10 l:mt-12`}

      font-size: 27px;
      line-height: 40px;

      @media (min-width: 640px) {
        ${tw`font-extrabold`}

        font-size: 44px;
        line-height: 55px;
      }
    }

    h5 {
      ${tw`text-grey-65 mt-6 l:mt-8`}

      font-size: 15px;
      line-height: 23px;

      @media (min-width: 640px) {
        font-size: 22px;
        line-height: 33px;
      }
    }
  }
`

const Demand360 = ({ data }) => {
  const { gcms } = data
  const { page, demand360ProductsInfo } = gcms
  const { featuredPressArticles, articleShowcase, featuredVideo, contentBlocks } = page
  const { headerContent, mainContent } = demand360ProductsInfo[0] || {}

  const { setSection, resetSection } = useContext(ActiveRouteContext)

  const [insightsRef, { isVisible: insightsIsVisible }] = useTrackVisibility({
    threshold: 0.4,
  })
  const [sentimentRef, { isVisible: sentimentIsVisible }] = useTrackVisibility({
    threshold: 0.4,
  })

  const videoSection = contentBlocks.find(
      ({ identifier }) => identifier === "video-section-block"
  )

  useEffect(() => {
    if (insightsIsVisible) {
      setSection("audience-insights")
    }

    if (sentimentIsVisible) {
      setSection("audience-sentiment")
    }
  }, [insightsIsVisible, sentimentIsVisible])

  useEffect(() => {
    return resetSection
  }, [])

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}

      <Demand360Globe className="pt-24 m:pt-40 demand360" />

      <SectionBackground bg={BgWaves} color="#0c0c0c" opacity="0.25">
        <Container>
          <div className="mx-auto max-w-s m:max-w-m l:max-w-l relative z-10 mb-16">
            <div className="flex flex-col items-center mt-4 l:mt-12">
              {(headerContent || mainContent) && (
                <div className="body">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: headerContent.html,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mainContent.html,
                    }}
                  />
                </div>
              )}
            </div>

            <div className="relative pt-16 l:pt-32 l:mt-12">
              <VerticalLine className="products" />
              <Demand360ProductProfiles />
            </div>

            {videoSection && (
                <ProductVideoSection
                    className="pt-12 mb-40 m:mb-48"
                    contentBlock={videoSection}
                />
            )}

            <Demand360AddonModule
              className="pt-16 m:pt-20 pb-4 m:pb-2"
              ref={insightsRef}
              identifier="audience-insights-module-section"
              id="audience-insights"
            />

            <Demand360AddonModule
              className="pt-16 pb-16"
              ref={sentimentRef}
              identifier="audience-sentiment-module-section"
              id="audience-sentiment"
            />
          </div>

          <StyledSlantedSection className="z-10 pt-16 l:pt-32 pb-0 demand360-lite">
            <Demand360Lite />
          </StyledSlantedSection>

          {page.featuredLogosClients && (
            <div className="mx-auto max-w-s m:max-w-m l:max-w-l mt-24 l:mt-40">
              <PartnerLogos data={page.featuredLogosClients} />
            </div>
          )}

          <ExploreOtherProducts className="mt-5" page="demand360" />

          {featuredVideo && (
            <div className="mt-12">
              <FeaturedVideo video={featuredVideo} />
            </div>
          )}

          {featuredPressArticles?.length > 0 && (
            <SlantedSection className="pt-4 l:pt-16 pb-24 z-10">
              <FeaturedPressCarousel
                className="dark"
                articles={featuredPressArticles}
              />
            </SlantedSection>
          )}
        </Container>
      </SectionBackground>

      {articleShowcase?.length > 0 && (
        <LightSlantedSection>
          <ArticleShowcase
            articles={articleShowcase}
            title="Insights that fuel industry conversations"
          />
        </LightSlantedSection>
      )}
    </Layout>
  )
}

export default Demand360

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/products/demand360" }) {
        ...PageData
      }
      demand360ProductsInfo: contentBlocks(
        where: { identifier: "demand360-products-info" }
      ) {
        headerContent {
          html
        }
        mainContent {
          html
        }
      }
    }
    # bgWaves: file(relativePath: { eq: "bg/waves.jpg" }) {
    #   childImageSharp {
    #     gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    #   }
    # }
  }
`
